.photo-bio {
  img {
    height: 200px !important;
    border-radius: 100%;
  }
}

.about-social-links {
  text-align: center;
  font-size: 24px;
}
